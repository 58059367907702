.embla {
  --slide-spacing: 1rem;
  --slide-size: 33%;
  --slide-height: 10rem;
  padding: 1.6rem;
}
@media (max-width: 959.95px) {
  .embla {
    --slide-size: 50%;
  }
}
@media (max-width: 600px) {
  .embla {
    --slide-size: 100%;
  }
}
.embla__viewport {
  overflow: hidden;
  width: 100%;
}
.embla__container {
  display: flex;
  flex-direction: row;
  height: auto;
  margin-left: calc(var(--slide-spacing) * -1);
}
.embla__slide {
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
  position: relative;
  display: flex;
  justify-content: center;
}
.embla__slide__img {
  display: block;
  height: var(--slide-height);
  /* width: 100%; */
  /* object-fit: cover; */
}
.embla__slide__number {
  width: 4.6rem;
  height: 4.6rem;
  z-index: 1;
  position: absolute;
  top: 0.6rem;
  right: 0.6rem;
  border-radius: 50%;
  background-color: rgba(var(--background-site-rgb-value), 0.85);
  line-height: 4.6rem;
  font-weight: 900;
  text-align: center;
  pointer-events: none;
}
.embla__slide__number > span {
  color: var(--brand-primary);
  background-image: linear-gradient(
    45deg,
    var(--brand-primary),
    var(--brand-secondary)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.6rem;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
