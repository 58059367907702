* {
  margin: 0;
  padding: 0;
  font-family: "Accura";
}
body {
  /* min-width: 550px; */
}
@font-face {
  font-family: "Accura SemiBold";
  src: local("Accura SemiBold"), url(./fonts/Accura-SemiBold.otf);
}
@font-face {
  font-family: "Accura";
  src: local("Accura"), url(./fonts/Accura.otf);
}
.App {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  /* max-width: 1250px; */
}
.link {
  color: #58585a;
  list-style: none;
}
.active-link {
  color: #fff;
}
.cont {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #fec011;
  clip-path: polygon(0 0, 100% 0, 100% 80%, 0% 100%);
  overflow: visible;
  width: 100%;
  height: 900px;
  z-index: -1;
}
.font-accura {
  font-family: Accura;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
}
.font-verdana {
  font-family: Verdana;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
}
.active-panel {
  background: rgba(0, 0, 0, 0.3);
}

.cls.centerImg {
  margin-top: 0px;
}
